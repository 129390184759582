<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#F0F0F0"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Bienvenue sur le site de votre émission de radio quotidienne 100%
            VINCI Construction ! Au programme : actualités prévention et
            sécurité au travail, expériences de compagnons de la division Route
            France, infos, sport, humour, météo.
            <br />
            <v-btn
              ref="btn"
              color="#E20025"
              rounded
              depressed
              class="white--text"
              @click.stop="handleOpen()"
            >
              <v-icon color="#ffffff" class="mr-2"> mdi-volume-high </v-icon>
              Regarder la vidéo de présentation
            </v-btn>
            <v-btn
              ref="btn"
              color="#184A8B"
              rounded
              depressed
              class="white--text"
              @click.stop="handleOpenBis()"
            >
              <v-icon color="#ffffff" class="mr-2"> mdi-volume-high </v-icon>
              Ecouter le teaser
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      showFirstFeedbackModal: false,
      showSecondFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },
  methods: {
    handleOpen(e) {
      this.$spoke
        .collection('contents')
        .getOne('66cf3042e78d0926cc3af033')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
    handleOpenBis(e) {
      this.$spoke
        .collection('contents')
        .getOne('665086b709e3be3a78f4256f')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  font-weight: bold;
  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
a {
  color: #e20025;
}
.link {
  color: #307bff;
}
</style>
